<template>
  <section id="tour-result" class="secction-h tour-section-package pb-5">
    <b-container class="bv-section1-row">
      <b-breadcrumb class="title-page py-5">
        <b-breadcrumb-item class="size-breadcrumb" active
          ><a href="#/"> {{$t('layout.home')}} </a></b-breadcrumb-item
        >
        <b-breadcrumb-item href="" class="size-breadcrumb text-info">
          {{$t('layout.search')}}: {{ keyword }}
        </b-breadcrumb-item>
      </b-breadcrumb>
      <b-row v-if="info && info.length > 0">
        <b-col
          class="col-12 col-lg-6"
          v-for="(value, key, index) in info"
          :key="`${key}-${index}`"
        >
          <b-card
            class="card-image mb-3"
            :style="{
              backgroundImage: `url(&quot;${oss+value.mainPicture}&quot;)`,
            }"
            style="background-image: url(/img/s2_b1.png)"
          >
            <div class="text-white rgba-black-strong py-1 px-3 py-2">
              <div>
                <b-row>
                  <b-col cols="8" class="text-left b-item">
                    <div class="b-title">
                      <h5 class="py-2">
                        {{ value.title }}
                      </h5>
                      <h6 class="purple-text">
                        {{ value.shortDescription }}
                      </h6>
                    </div>
                  </b-col>
                  <b-col cols="4" class="text-right align-bottom pb-1 b-item">
                    <a :href="`#/tour/${value.packageCode}`"
                      ><b-button squared variant="outline-secondary">{{
                        $t('View_details')
                      }}</b-button></a
                    >
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <div v-else class="w-100">
        <b-container v-if="loading">
          <Spinner />
        </b-container>
        <p
          class="text-center my-4"
          v-if="info && info.length == 0 && loading == false"
        >
          <img class="mw-100" src="../../assets/img/search_not_found.png" alt="Image" />
        </p>
      </div>
    </b-container>
  </section>
</template>
<script>
import Spinner from '@/Layout/spinner/Spinner';
import configUrl from '@/global/config.js'
export default {
  name: 'PackageList',
  components: {
    Spinner,
  },
  data() {
    return {
      info: null,
      loading: true,
      errored: false,
      infolength: null,
      slug: null,
      key_word: null,
       oss:''
    };
  },
  mounted() {
    this.oss=configUrl.oss_path
    this.getSearchResult()
  },
  watch:{
    $route(value){
      this.getSearchResult()
    }
  },
  methods:{
    getSearchResult(){
      var param = this.$route.params.query_search;
    this.keyword = param;
    this.$store
      .dispatch('tourModule/fetchSeachResult', param)
      .then(() => {
        const data = this.$store.getters['tourModule/getSearchResult'];
        this.info = data;
        this.loading = false;
      })
      .catch((error) => {
        this.errored = true;
      })
      .finally(() => (this.loading = false));
    }
  },
};
</script>
<style type="text/css">
.tour-section-package {
  margin-top: 5rem !important;
}
.align-bottom {
  align-self: flex-end;
}
.tour-section-package .card {
  background-repeat: no-repeat;
  min-height: 28rem;
  background-size: cover;
  border-radius: unset;
  flex-direction: column-reverse;
}
.tour-section-package.card h5,
.tour-section-package .card h6 {
  font-weight: 400;
}
.breadcrumb {
  background-color: #fff !important;
}

.breadcrumb-item + .breadcrumb-item::before {
  color: #000000;
  content: '>';
}

.align-bottom {
  align-self: flex-end;
}

.secction-h button {
  background-color: #f6a604;
  padding: 0.3rem 1rem;
  border-radius: 4px !important;
  border: 1px solid #f6a604;
}
.area-sub-title {
  font-size: 18px;
  font-weight: 400;
  line-height: 31px;
  color: #666666;
  opacity: 1;
  margin-left: 0.5rem;
}

.card-body {
  align-items: end;
  display: grid;
}

.b-title {
  color: white;
  text-shadow: -1px -1px 0 #ff7e09, 1px -1px 0 #ff7e09, -1px 1px 0 #ff7e09,
    1px 1px 0 #ff7e09;
}

.size-breadcrumb {
  font-weight: 500;
  font-size: 1.6625rem;
}

.title-page {
  height: 70px;
  padding-top: 32px;
}

#tour-result .card-body {
  display: contents;
}
</style>
